const state = {
  targetOrder: null,
  newOrderItems: [],
};

const getters = {
  totalAmount: (state) => {
    return state.newOrderItems.reduce( (total, product) => {
      return total + (product.product_regular_price * product.quantity)
    }, 0 )
  },
}

const mutations = {
  setTargetOrder(nextState, order){
    nextState.targetOrder = order;
  },
  cleanOrderItem(nextState){
    nextState.newOrderItems = [];
  },
  addOrderItem(nextState, item){
    const qty = item.quantity ? item.quantity : 1
    nextState.newOrderItems.push({...item, quantity: qty  });
  },
  removeOrderItem(nextState, item){
    const index = nextState.newOrderItems.indexOf(item)
    nextState.newOrderItems.splice(index, 1)
  },
  updateTargetOrder(nextState, order){
    nextState.targetOrder = order;
  },
};

const actions =  {
  cleanOrderItem: (context) => context.commit('cleanOrderItem'),
  addOrderItem: (context, item) => context.commit('addOrderItem', item),
  removeOrderItem: (context, item) => context.commit('removeOrderItem', item),
  setTargetOrder: (context, order) => context.commit('setTargetOrder', order),
  removeTargetOrder: (context) => context.commit('setTargetOrder', null),
};

export default {
  state,
  actions,
  getters,
  mutations,
};
