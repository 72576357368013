import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  //Orders
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/order/Orders.vue')
  },
  {
    path: '/order/create',
    name: 'CreateOrder',
    component: () => import('../views/order/CreateOrder.vue')
  },
  {
    path: '/order/complete/:id',
    name: 'CompleteOrder',
    component: () => import('../views/order/CompleteOrder.vue')
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: () => import('../views/order/Order.vue')
  },

  //Inventory
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../views/inventory/Inventory.vue')
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/report/Report.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
